import React, { useState, useEffect } from 'react';
import { MDXProvider } from '@mdx-js/react';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {makeStyles, createMuiTheme } from '@material-ui/core/styles'
import { Hidden, ThemeProvider, useTheme, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import mdxComponents from './mdxComponents';
import Navigation from './navigation';
import RightSidebar from './rightSidebar';
import Footer from './footer'
import Container from '@material-ui/core/Container';
import Header from './header'

const darkTheme = createMuiTheme({
  typography: {
    h1: {
      fontSize: '2.2rem',
    },
    h2: {
      fontSize: '1.8rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#303030',
    },
    secondary: {
      main: '#e66e15',
    },
  },
});

const lightTheme = createMuiTheme({
  typography: {
    h1: {
      fontSize: '2.2rem',
    },
    h2: {
      fontSize: '1.8rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#3c3c3c',
    },
    secondary: {
      main: '#a14d0e',
    },
  },
});


export const drawerWidth = 340;

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  wrapper: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  title: {
    margin: '10px 0 20px 0'
  },
   bodyContainer: {
    display: 'flex',
    height: '100%',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '93vh',
    paddingTop: '10px',
    width: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

const themeEnum = {
  light: 'light',
  dark: 'dark'
}

const Layout = ({ children, location, title }) => {
  const classes = useStyles();

  const theme = useTheme();

  const [currentTheme, setCurrentTheme] = useState(themeEnum.light)

  const [isMenuOpen, setIsMenuOpen ] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => {
      return !prev
    })
  }

  useEffect(() => {
    if (window.localStorage.getItem('theme')) {
      setCurrentTheme(window.localStorage.getItem('theme'));
    }
  }, [])

  useEffect(() => {window.localStorage.setItem('theme', currentTheme)}, [currentTheme])


  const toggleTheme = () => {
    setCurrentTheme((prev) => prev === themeEnum.light ? themeEnum.dark : themeEnum.light)
  }

  return (
    <ThemeProvider theme={currentTheme === themeEnum.dark ? darkTheme : lightTheme}>
      <MDXProvider components={mdxComponents}>
        <ThemeProvider theme={darkTheme}>
          <Header toggleMenu={toggleMenu} toggleTheme={toggleTheme} />
        </ThemeProvider>
        <div className={classes.wrapper}>
          <Hidden xsDown implementation="css">
            <Drawer
              anchor="left"
              onClose={() => {}}
              className={classes.drawer}
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
            >
              <Navigation location={location} />
            </Drawer>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Drawer
              anchor="left"
              open={isMenuOpen}
              onClose={() => {}}
              className={classes.drawer}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                Comon Codex
                <IconButton onClick={toggleMenu}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </div>
              <Divider />
              <Navigation location={location} />
            </Drawer>
          </Hidden>
          <div className={classes.content}>
            <Container maxWidth="lg" className={classes.bodyContainer}>
              <div style={{ width: '100%' }}>
                <Typography className={classes.title} variant="h1">
                  {title}
                </Typography>
                {children}
              </div>
              <Hidden xsDown implementation="css">
                <div>
                  <RightSidebar location={location} />
                </div>
              </Hidden>
            </Container>
            <Footer />
          </div>
        </div>
      </MDXProvider>
    </ThemeProvider>
  );
}

export default Layout;
