import * as React from 'react';
import { Link } from '@material-ui/core'

const AnchorTag = ({ children: link, ...props }) => {
  if (link) {
    return (
      <Link color='secondary' href={props.href} target="_blank" rel="noopener noreferrer">
        {link}
      </Link>
    );
  } else {
    return null;
  }
};

export default AnchorTag;
