const config = {
  gatsby: {
    pathPrefix: '',
    siteUrl: 'https://docs.comon.ru',
    trailingSlash: true,
  },
  sidebar: {
    forcedNavOrder: [
      'general-information',
      'follower-information',
      'trader-information',
      'trading-terminal',
      'trade-api',
      'frequently-asked-questions',
      'video-instructions',
      'glossary',
    ],
    forcedNavChildren: {
      'video-instructions': [
        'edox-authorisation-video',
        'basic-account-settings-video',
        'search-classify-compare-strategies-video',
        'strategy-key-figures-video',
        'start-copy-trading-video',
        'subscription-management-video',
        'follower-information-video',
      ],
      'frequently-asked-questions': [
        'copy-trading-faq',
        'authorization-errors',
        'how-to-unsubscribe-from-mailouts',
        'i-have-a-question',
        'strategy-description-examples',
      ],
      'general-information': [
        'rules',
        'registration-and-login',
        'account-connection',
        'trade-signals',
        'copy-trading',
        'schedule-and-financial-instruments',
        'investor-risk-level',
        'tariffs',
        'synchronization',
        'yield',
        'max-drawdown',
        'multiple-strategies',
        'money-box',
        'information-security',
        'support',
        'system-requirements',
        'settings',
        'informing',
        'service-administration',
      ],
      'trader-information': [
        'strategy-creation',
        'reward',
        'assent-obligation',
        'index-demo-strategy',
        'strategy-description-standards',
        'risk-profile-estimation-strategy',
        'restrictions-and-requirements',
      ],
      'follower-information': [
        'start-service',
        'investment-profile',
        'qualification-level',
        'risk-notice',
        'portfolio-recommendations',
        'subscription-management',
        'service-disactivation',
        'yield-divergence',
        'tariff-choice',
        'limitations-and-perculiarities',
      ],
      'trading-terminal': [
        'order-placement',
        'chart-instruments',
        'portfolio-key-figures',
        'trading-chat',
        'user-moderation',
      ],
    },
  },
  siteMetadata: {
    title: 'Правила сайта',
    description: 'Правила сайта',
    ogImage: 'https://files.comon.ru/2tysxu6j2jlj4ot8pga9eelx8mm4ydqs3r2ws2vyt4ukp64c2t.jpg',
    docsLocation: '',
    favicon: 'https://www.comon.ru/favicon.ico',
  },
};

module.exports = config;
