import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mdListElement: {
    margin: '5px 0',
  },
}));

const ListElement = ({ children }) => {
  const classes = useStyles();

  return <Typography component='li' className={classes.mdListElement}>{children}</Typography>
};

export default ListElement;
