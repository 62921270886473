import React from 'react'
import {makeStyles, AppBar, Toolbar, IconButton, Tooltip} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import Logo from './logo';
import Search from './search';
import {drawerWidth} from './layout'

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,

  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },

  menuIcon: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },

}));

const Header = ({ toggleMenu, toggleTheme }) => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.menuIcon}
          onClick={toggleMenu}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Logo />
        <Search />
        <Tooltip title='Сменить тему'>
            <IconButton onClick={toggleTheme}>
            <Brightness6Icon />
            </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Header