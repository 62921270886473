import React from 'react';
import Tree from './tree';
import { StaticQuery, graphql } from 'gatsby';

const Navigation = ({ location }) => {
 return (
    <StaticQuery
      query={graphql`
        query {
          allMdx {
            edges {
              node {
                fields {
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={({ allMdx }) => {
        return (
            <Tree edges={allMdx.edges} location={location}/>

        );
      }}
    />
  );
}

export default Navigation;
