import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import cx from 'classnames'
import './warning.css'

const useStyles = makeStyles((theme) => ({
    textStyle: {
        fontStyle: 'italic'
    },
    warningText: {
      color: `${theme.palette.warning.contrastText}`
    }
}))

const Warning = ({ children, italic = false }) => {
    const classes = useStyles();

    return (
    <div className={'warning'}>
        <div className="warning-title">
        <Typography>Внимание</Typography>
        </div>
        <Typography className={cx(classes.warningText, {
          [classes.textStyle]: italic
        })} component='p'>{children}</Typography>
    </div>
    );
}

export default Warning;