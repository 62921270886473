import React from 'react';
import YouTube from './youtubeEmbed';
import Note from './note'
import CodeBlock from './codeBlock';
import AnchorTag from './anchor';
import Warning from './warning';
// import Table from './table/table'
import Paragraph from './paragraph'
import List from './list'
import ListElement from './listElement'
import Heading from './heading'
import TableCell from './table/td'
import {TableBody, TableHead, Link, TableRow, Table, Paper } from '@material-ui/core';

export default {
  h1: props => <Heading variant="h1" {...props} />,
  h2: props => <Heading variant="h2" {...props} />,
  h3: props => <Heading variant="h3" {...props} />,
  h4: props => <Heading variant="h4" {...props} />,
  h5: props => <Heading variant="h5" {...props} />,
  h6: props => <Heading variant="h6" {...props} />,
  p: Paragraph,
  pre: props => <pre {...props} />,
  ul: props => <List {...props} />,
  ol: props => <List ordered {...props} />,
  li: ListElement,
  code: CodeBlock,
  a: AnchorTag,
  YouTube: YouTube,
  Note,
  Warning,
  table: (props) => <Paper style={{overflowX: 'auto'}}> <Table {...props} /></Paper>,
  tr: TableRow,
  tbody: TableBody,
  thead: TableHead,
  td: TableCell,
  th: (props) => <TableCell {...props} isHead/>,
  img: (props) => <img style={{maxWidth: '100%'}} {...props} />
  // TODO add `img`
  // TODO add `blockquote`
  // TODO add `ul`
  // TODO add `li`
  // TODO add `table`
};
