import React from 'react';
import { Button, makeStyles } from '@material-ui/core'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import config from '../../config'
import { getBottomNavigationOrder } from '../utils/navigationHelpers'

const useStyles = makeStyles(theme => ({
  navBtnContainer: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  navButton: {
    width: '48%',
    minHeight: '80px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  navButtonNext: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
  },
}));

const NextPrevious = ({ mdx, edges }) => {
  const navItems = getBottomNavigationOrder(edges);

  const path = mdx.fields.slug
    .split('/')
    .filter(item => item !== '');

  const nodeId = path[path.length - 1]

  const classes = useStyles();

  const currentItemIdx = navItems.findIndex((item) => item.id === nodeId )

  const nextItem = navItems[(currentItemIdx + 1) % navItems.length]

  const prevItem =
    currentItemIdx === 0 ? navItems[navItems.length - 1] : navItems[currentItemIdx - 1];

  return (
    <div className={classes.navBtnContainer}>
        <Button
          className={`${classes.navButton} ${classes.navButtonNext}`}
          color="secondary"
          href={prevItem.url}
          variant="outlined"
          id="go-previous-page-btn"
        >
          <SkipPreviousIcon />
          {prevItem.title}
        </Button>

        <Button
          className={classes.navButton}
          href={nextItem.url}
          variant="outlined"
          color="secondary"
          id="go-next-page-btn"
        >
          {nextItem.title}
          <SkipNextIcon />
        </Button>
    </div>
  );
};

export default NextPrevious;
