import React, { useState } from 'react';
import { List, makeStyles, ListItem, Paper, TextField, ListItemText } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    '& input': {
      padding: '10px'
    }
  },
  searchContainer: {
    display: 'flex',
    width: 250,
    position: 'relative'
  },
  searchIcon: {
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  dropDown: {
    position: 'absolute',
    top: 45,
    right: 0,
    zIndex: 1,
    minWidth: 250
  }

}));

const Search = () => {
  const [query, setQuery] = useState('')

  const classes = useStyles();

  const [results, setResults] = useState([]);

  const getSearchResults = (query) => {
    if (!query || !window.__LUNR__) return [];
    const results = window.__LUNR__['ru'].index.search(query);

    return results.map(({ ref }) => window.__LUNR__['ru'].store[ref]);
  }


  const search = (event) => {
    const query = event.target.value;

    setQuery(query)
    const results = getSearchResults(query);

    setResults(results.splice(0,5))
  };

  return (
    <div className={classes.searchContainer}>
      <TextField
        variant="filled"
        className={classes.searchInput}
        type="text"
        value={query}
        onChange={search}
        placeholder={'Поиск'}
        InputProps={{
          startAdornment: <SearchIcon className={classes.searchIcon}/>,
          id: "header-search-input",
        }}
      />
      {Boolean(results.length) && <Paper className={classes.dropDown}>
        <List>
          {results.map(page => (
            <ListItem button key={page.title} component='a' href={page.slug}>
              <ListItemText primary={page.title} />
            </ListItem>
          ))}
        </List>
      </Paper>}
    </div>
  );

}

export default Search;
