import { BottomNavigation } from '@material-ui/core';
import { sidebar } from '../../config';


export const getHierarchicalDataFromSlug = edges => {
    edges = edges.map(edge => edge.node);
  let treeData = [];

  edges.forEach(({ fields }) => {
    const path = fields.slug.split('/').filter(slug => slug !== '');

    if (path.length === 1) {
      let nodeIsFoundInTree = false;

      treeData = treeData.map(currentNode => {
        if (currentNode.id === path[0]) {
          nodeIsFoundInTree = true;
          return {
            ...currentNode,
            title: fields.title,
            id: path[0],
            url: fields.slug,
          };
        }
        return {
          ...currentNode,
        };
      });

      if (!nodeIsFoundInTree) {
        treeData.push({
          title: fields.title,
          children: [],
          id: path[0],
          url: fields.slug,
        });
      }
    } else {
      const parentNode = treeData.find(({ id }) => id === path[0]);

      if (!parentNode) {
        treeData.push({
          title: '',
          children: [
            {
              title: fields.title,
              children: [],
              id: path[1],
              url: fields.slug,
            },
          ],
          id: path[0],
        });
      } else {
        parentNode.children.push({
          title: fields.title,
          children: [],
          id: path[1],
          url: fields.slug,
        });
      }
    }
  });

  return treeData;
};

export const sortTreeData = treeData => {
  treeData.sort(
    (a, b) => sidebar.forcedNavOrder.indexOf(a.id) - sidebar.forcedNavOrder.indexOf(b.id)
  );
  treeData.forEach(item => {
    const childrenNavOrder = sidebar.forcedNavChildren[item.id];

    item.children.sort(({ id: a }, { id: b }) => {
      return childrenNavOrder.indexOf(a) - childrenNavOrder.indexOf(b);
    });
  });
};

export const getBottomNavigationOrder = edges => {
    const hierarchy = getHierarchicalDataFromSlug(edges);

    sortTreeData(hierarchy);

    const botomNavMap = []

    hierarchy.forEach(({children, url, title, id}) => {
        botomNavMap.push({
            title: title,
            url: url,
            id: id
        })

        children.forEach((child) => {
             botomNavMap.push({
               title:child.title,
               url:child.url,
               id:child.id,
             });
        });
    })

    return botomNavMap;
}
