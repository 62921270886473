import React, { useState  } from 'react';
import { Link, makeStyles } from '@material-ui/core'
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {getHierarchicalDataFromSlug, sortTreeData} from '../../utils/navigationHelpers'

const useStyles = makeStyles(theme => ({
  treeItem: {
    margin: '5px 0',
  },
  tree: {
    padding: '15px',
  },

  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const Tree = ({ edges, location }) => {
  const classes = useStyles();

  let treeData = getHierarchicalDataFromSlug(edges)

  sortTreeData(treeData)

  const path = location.pathname.trim().split('/').filter(part => part !== '')

  const selectedNode = path[path.length - 1];

  const [expandedNodes, setExpandedNodes] = useState([path[0]])

  const renderTree = nodes =>
    nodes.map(node => (
      <TreeItem
        id={`tree-item-${node.id}`}
        className={classes.treeItem}
        classes={{selected: classes.selectedItem}}
        key={node.id}
        onLabelClick={() => (window.location.href = node.url)}
        onIconClick={() => {
          setExpandedNodes((prev) => prev.includes(node.id) ? [] : [node.id])
        }}
        nodeId={node.id}
        label={
          <Link id={`tree-item-label-link-${node.id}`} href={node.url} color='textPrimary' className={classes.link}>
            {node.title}
          </Link>
        }
      >
        {Array.isArray(node.children) ? renderTree(node.children) : null}
      </TreeItem>
    ));

   return (
     <TreeView
       className={classes.tree}
       selected={[selectedNode]}
       expanded={expandedNodes}
       defaultCollapseIcon={<ExpandMoreIcon />}
       defaultExpandIcon={<ChevronRightIcon />}
       defaultSelected={[selectedNode]}
     >
       {renderTree(treeData)}
     </TreeView>
   );
};

export default Tree;
