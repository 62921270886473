import React from 'react'
import { Paper, makeStyles, Typography, Breadcrumbs, Link } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    footer: {
        padding: '20px',
        borderTop: '1px solid lightgrey',
        marginTop: '40px',
        alignItems: 'center',

    },
    breadcrumps: {
        marginBottom: '20px'
    }
}))

const Footer = () => {
    const classes = useStyles();

    return (
      <Paper elevation={0} square className={classes.footer}>
        <Breadcrumbs className={classes.breadcrumps}>
          <Link href="https://www.comon.ru/managers/" color="textPrimary">
            Стратегии
          </Link>
          <Link href="https://www.comon.ru/info/news/" color="textPrimary">
            Новости
          </Link>
          <Link href="https://www.comon.ru/info/support/" color="textPrimary">
            Поддержка
          </Link>
          <Link href="https://www.comon.ru/info/contact/" color="textPrimary">
            Контакты
          </Link>
          <Link href="http://zaoik.finam.ru/statements/own-funds/" color="textPrimary">
            Раскрытие информации
          </Link>
        </Breadcrumbs>
        <Typography variant="body2" color="textSecondary">
          Информация, публикуемая пользователями на сайте (в блогах, комментариях, чате, сообществах
          и т.д.) является личным (субъективным) мнением, суждением или выражением конкретного
          пользователя и не может являться основополагающей для принятия инвестиционных решений
          (покупки и/или продажи ценных бумаг) или побуждающей совершить какие-либо юридические или
          фактические действия. Администрация сайта не несёт ответственности за принятые решения,
          основанные на такой информации, а также в случае если такая информация направлена на
          возбуждение ненависти и/или вражды по отношению к каким-либо группам лиц, народам
          каких-либо национальностей, расам и каким-либо другим социальным группам и категориям лиц.
        </Typography>
      </Paper>
    );
}

export default Footer;