import React from 'react';
import { makeStyles, TableCell as UITableCell, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cellHead: {
    fontWeight:'bold'
  },
}));

const TableCellHead = ({ children, isHead = false }) => {
  const classes = useStyles();

  return (
    <UITableCell>
      <Typography className={isHead ? classes.cellHead: ''}>{children}</Typography>
    </UITableCell>
  );
};

export default TableCellHead;
