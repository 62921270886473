import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mdList: {
    margin: '15px 0 15px 17px',
  },
}));

const Paragraph = ({ children, ordered }) => {
  const classes = useStyles();
    
    return ordered ? (
      <ol className={classes.mdList}>{children}</ol>
    ) : (
      <ul className={classes.mdList}>{children}</ul>
    );
};

export default Paragraph;
