import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mdHeading: {
    margin: '35px 0 15px 0',
    textAlign: 'left'
  },
}));

const Heading = ({ children, variant = 'h3' }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.mdHeading} variant={variant} id={children.replace(/\s+/g, '').toLowerCase()}  >{children}</Typography>
  );
};

export default Heading;
