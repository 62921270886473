import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mdParagraph: {
    margin: '15px 0',
  },
}));

const Paragraph = ({children}) =>{
    const classes = useStyles();

    return <Typography component='p' className={classes.mdParagraph}>{children}</Typography>;
}

export default Paragraph;