import React from 'react'
import { makeStyles } from '@material-ui/core'
import LogoIcon from '../assets/finam.svg';
import FlagIcon from '../assets/logo.svg';

const useStyles = makeStyles((theme) => ({
    text: {
        backgroundImage: `url(${LogoIcon})`,
        backgroundRepeat: 'no-repeat',
        height: '28px',
        width: '85px',
        marginRight: '10px'
    },
    flag: {
        backgroundImage: `url(${FlagIcon})`,
        backgroundRepeat: 'no-repeat',
        height: '30px',
        width: '35px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    logoContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'baseline',
        gap: '8px'
    }
}))


const Logo = () => {
    const classes = useStyles();

    return (
        <a href="https://comon.ru" target='_blank' rel="noopener noreferrer" className={classes.logoContainer}>
            <div className={classes.flag} />
            <div className={classes.text} />
        </a>
    )
}

export default Logo
