import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// import Link from './link';
import config from '../../config';
import { Typography, makeStyles, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sideMenu: {
    position: 'sticky',
    top: '70px',
    width: '300px',
    padding: '15px 15px 0 15px'
  },
  offset: theme.mixins.toolbar,

  listElement: {
    listStyle: 'none',
    paddingBottom: '5px'
  },

  list: {
    paddingLeft: '10px'
  }

}))


const SidebarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
              }
              tableOfContents
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      let navItems = [];

      let finalNavItems;

      const classes = useStyles();

      if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
        const navItems = allMdx.edges.map((item, index) => {
          let innerItems;

          if (item !== undefined) {
            if (
              item.node.fields.slug === location.pathname ||
              config.gatsby.pathPrefix + item.node.fields.slug === location.pathname
            ) {
              if (item.node.tableOfContents.items) {
                innerItems = item.node.tableOfContents.items.map((innerItem, index) => {
                  const itemId = innerItem.title
                    ? innerItem.title.replace(/\s+/g, '').toLowerCase()
                    : '#';

                  return (
                    <li key={index} className={classes.listElement}>
                      <Link color='textPrimary' className={classes.link} href={`#${itemId}`} level={1}>
                        <Typography variant='subtitle2'>{innerItem.title}</Typography>
                      </Link>
                    </li>
                  );
                });
              }
            }
          }
          if (innerItems) {
            finalNavItems = innerItems;
          }
        });
      }

      if (finalNavItems && finalNavItems.length) {
        return (
          <div className={classes.sideMenu}>
            <Typography>Содержание</Typography>
            <ul className={classes.list}>{finalNavItems}</ul>
          </div>
        );
      } else {
        return null
      }
    }}
  />
);

export default SidebarLayout;
