import React from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core'
import './note.css'

const useStyles = makeStyles((theme) => ({
  noteText: {
    color: theme.palette.warning.contrastText
  }
}))

const Note = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={'note'}>
      <div className="note-title">
        <Typography>Примечание</Typography>
      </div>
      <Typography component='p' className={classes.noteText}>{children}</Typography>
    </Box>
  )
}

export default Note;